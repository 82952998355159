<template>
  <el-main>
    <page-title />
    <div class="partition-area">
      <ics-search-inner :search-form="searchForm" more-btn="高级搜索" :fold-search.sync="showFoldSearch" @submit-search="search" @clear-search="clearSearch">
        <template>
          <el-col :span="6">
            <el-form-item label="姓名">
              <el-input v-model="searchForm.name" placeholder="请输入要搜索的姓名" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="企业名称">
              <el-input v-model="searchForm.firmName" placeholder="请输入要搜索的企业名称" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="统一社会信用代码">
              <el-input v-model="searchForm.firmSocialNo" placeholder="请输入要搜索的统一社会信用代码" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="企业类型">
              <el-select v-model="searchForm.firmType" placeholder="请选择要搜索的企业类型" filterable>
                <el-option label="全部" value="" />
                <el-option v-for="item in _.filter(constants.companyType,{states:1})" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-show="showFoldSearch" :span="12">
            <el-form-item label="创建时间">
              <ics-search-date :date.sync="createdArr" />
            </el-form-item>
          </el-col>
          <el-col v-show="showFoldSearch" :span="6">
            <el-form-item label="手机号码">
              <el-input v-model="searchForm.mobile" placeholder="请输入要搜索的手机号码" />
            </el-form-item>
          </el-col>
          <el-col v-show="showFoldSearch" :span="6">
            <el-form-item label="状态">
              <el-select v-model="searchForm.state" placeholder="请选择要搜索的状态" filterable>
                <el-option label="全部" value="" />
                <el-option v-for="item in constants.accountState" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
        </template>
      </ics-search-inner>
    </div>
    <div class="partition-table">
      <el-radio-group v-model="selectAccessUser" class="table-radio-group">
        <ics-table-inner v-loading="loading.list" :column-option="columnOption" :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" :hide-export-btn="true" @changePageNum="changePageNum">
          <template slot="btn-inner">
            <el-button type="primary" @click="$router.push({ name: 'admittanceEdit', params: { editMode: 'add' } })">
              新增管理员账号
            </el-button>
            <el-button :disabled="this.selectAccessUser.state !== '0'" type="primary" @click="agencyAccess">
              (代)准入
            </el-button>
          </template>
          <template slot="table-columns-before">
            <el-table-column label="选择" align="center" width="60">
              <template slot-scope="scope">
                <el-radio :label="scope.row" />
              </template>
            </el-table-column>
            <el-table-column label="序号" type="index" :index="indexMethod" />
          </template>
          <template slot="table-columns-after">
            <el-table-column fixed="right" label="操作" width="120">
              <template v-slot="scope">
                <el-link v-if="scope.row.state === '0'" type="primary" class="text-btn" @click="$router.push({ name: 'admittanceEdit', params: { editMode: 'edit' }, query: { id: scope.row.id } })">
                  编辑
                </el-link>
                <el-link v-if="scope.row.state === '1'" type="primary" class="text-btn" @click="$router.push({ name: 'admittanceSupplement', params: { editMode: 'supplement' }, query: { firmId: scope.row.firmId, firmType: scope.row.firmType } })">
                  补件
                </el-link>
                <el-link v-if="scope.row.state === '2' && scope.row.taskId !== '-'" type="primary" class="text-btn" @click="$router.push({ name: 'admittanceAudit', params: { editMode: 'audit' }, query: { firmId: scope.row.firmId, firmType: scope.row.firmType, taskId: scope.row.taskId } })">
                  审核
                </el-link>
                <el-link v-if="!['0'].includes(scope.row.state) && scope.row.firmType !== 'finance-frame-capital'" type="primary" class="text-btn" @click="$router.push({ name: 'admittanceDetail', query: { firmId: scope.row.firmId, firmType: scope.row.firmType, state: scope.row.state } })">
                  详情
                </el-link>
              </template>
            </el-table-column>
          </template>
        </ics-table-inner>
      </el-radio-group>
    </div>
  </el-main>
</template>

<script>
import { basePageListMixin } from '@/assets/js/mixins'
import utils from '@/assets/js/utils'
export default {
  components: {},
  mixins: [basePageListMixin],
  data () {
    return {
      showFoldSearch: false,
      baseSearchForm: {
        firmName: '',
        name: '',
        firmSocialNo: '',
        firmType: '',
        mobile: '',
        state: '',
        createdBeginAt: '',
        createdEndAt: ''
      },
      selectAccessUser: '',
      multipleSelection: undefined,
      searchNotCoreForm: {
        coreName: '',
        coreSocialNo: ''
      },
      loadingCore: {
        list: false
      },
      notChannelOfCore: {},
      dialog: {
        visible: false,
        title: ''
      },
      columnOption: [
        { label: '企业编号', prop: 'firmCode', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '企业名称', prop: 'firmName', formatter: this.utils.isEffective, minWidth: 230 },
        { label: '企业类型', prop: 'firmType', formatter: (row, col, cell) => this.utils.statusFormat(String(cell), 'companyType'), minWidth: 100 },
        { label: '统一社会信用代码', prop: 'firmSocialNo', formatter: this.utils.isEffective, minWidth: 160 },
        { label: '姓名', prop: 'name', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '手机号', prop: 'mobile', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '身份证号码', prop: 'idCardNo', formatter: this.utils.isEffective, minWidth: 160 },
        { label: '创建时间', prop: 'createdAt', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '创建人', prop: 'createdBy', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '状态', prop: 'state', formatter: (row, col, cell) => this.utils.statusFormat(String(cell), 'accountState'), minWidth: 100 },
      ]
    }
  },
  computed: {
    createdArr: utils.computedDate('createdBeginAt', 'createdEndAt')
  },
  methods: {
    getList () {
      this.loading.list = true
      this.api.admittance.getAccessUser(this.utils.filterTrimValue(this.searchForm)).then(result => {
        this.tableList = result.data.data
      }).finally(() => {
        this.loading.list = false
      })
    },
    agencyAccess () {
      if (!this.selectAccessUser) {
        this.$message.error('请选择需要进行(代)准入管理员')
        return false
      }
      this.$router.push({ name: 'agencyAccess', params: { editMode: 'agency' }, query: { firmId: this.selectAccessUser.firmId, firmType: this.selectAccessUser.firmType } })
    },
    enableChannel (channelId, enabled) {
      let api = ''
      if (enabled === 0) {
        api = this.api.company.channel.disable
      } else {
        api = this.api.company.channel.enable
      }
      api(channelId).then(result => {
        this.$message.success(result.data.message || '操作成功')
        this.getList()
      }).finally(() => {
      })
    }
  }
}
</script>

<style>

</style>
